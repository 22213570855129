import React from "react";
import Typed from "react-typed";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Container from "../Container";
import Buttons from "../Buttons";

const createGroupCover = (coverData) => {
  return { cover: coverData.cover.childImageSharp.fluid.src };
};

const Hero = ({ data }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  let covers = [];
  let group1 = [];
  let group2 = [];
  let group3 = [];

  data.covers.slice(0, 2).forEach((coverData) => {
    group1 = [...group1, createGroupCover(coverData)];
  });
  data.covers.slice(2, 5).forEach((coverData) => {
    group2 = [...group2, createGroupCover(coverData)];
  });
  data.covers.slice(5, 9).forEach((coverData) => {
    group3 = [...group3, createGroupCover(coverData)];
  });

  covers = [{ group: group1 }, { group: group2 }, { group: group3 }];

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to bottom, white, rgb(247, 250, 255))} 100%)`,
        backgroundRepeat: "repeat-x",
        position: "relative",
      }}
    >
      <Box paddingY={{ xs: 0, sm: "4rem", md: "8rem" }}>
        <Container>
          <Box maxWidth={{ xs: 1, sm: "50%" }}>
            {data.title && (
              <Typography
                variant="h2"
                color="text.primary"
                gutterBottom
                sx={{
                  fontWeight: 700,
                }}
              >
                <Grid xs={12}>
                  {data.title}
                </Grid>
                <Grid xs={12}>
                  <Typography
                    color={"primary"}
                    component={"span"}
                    variant={"inherit"}
                    sx={{
                      background: `linear-gradient(180deg, transparent 82%, lightblue 0%)`,
                    }}
                  >
                    {" "}
                    <Typed
                      strings={data.animated_words?.split(",")}
                      typeSpeed={80}
                      loop={true}
                    />
                  </Typography>
                </Grid>
              </Typography>
            )}
            {data.paragraph && (
              <Typography
                variant="h6"
                component="p"
                color="text.secondary"
                sx={{ fontWeight: 400 }}
                dangerouslySetInnerHTML={{
                  __html: `${data.paragraph}`,
                }}
              />
            )}

            {data.buttons && (
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "stretched", sm: "flex-start" }}
                marginTop={4}
              >
                <Buttons data={data.buttons} />
              </Box>
            )}
          </Box>
        </Container>
        <Box
          sx={{
            transform: "rotate(-20deg)",
            display: { xs: "none", sm: "block" },
          }}
        >
          <Box
            display={"flex"}
            width={"50rem"}
            left={"50%"}
            top={0}
            position={"absolute"}
            sx={{ transform: "translate3d(20%, -50%, 0)" }}
          >
            {covers.map((item, i) => (
              <Box key={i} marginTop={{ sm: -(i * 16) }} marginX={1}>
                {item.group.map((g, j) => (
                  <Box
                    key={j}
                    padding={1}
                    bgcolor={"white"}
                    borderRadius={2}
                    boxShadow={3}
                    marginTop={2}
                  >
                    <Box
                      component={LazyLoadImage}
                      effect="blur"
                      src={g.cover}
                      height={1}
                      width={1}
                      maxWidth={320}
                    />
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        component={"svg"}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: "100%",
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
